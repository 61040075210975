import {useState, useEffect, useRef} from 'react';
import {setUserListener, postUserMessage, getDemoUser} from '../lib/pumpRoomUser';
import {removeListener} from '../lib/messages';
import {AuthResult} from '../types';
import config from '../config';

const useUser = (demoUser: boolean): { user?: AuthResult; userPollingSetup: boolean } => {
    const [user, setUser] = useState<AuthResult | undefined>(undefined);
    const [userPollingSetup, setUserPollingSetup] = useState(false);
    const intervalRef = useRef<number | undefined>(undefined);

    useEffect(() => {
        let userListener: ((event: MessageEvent) => void) | undefined;

        if (demoUser) {
            console.log('Setting up demo User');
            setUser(getDemoUser());
        } else {
            const setUserFromPumpRoom = (pumproomUser: AuthResult) => {
                setUser(prevUser => {
                    if (intervalRef?.current) {
                        clearInterval(intervalRef.current);
                        intervalRef.current = undefined;
                    }
                    if (!prevUser) {
                        return pumproomUser; // устанавливаем состояние только раз, иначе - игнорируем
                    } else {
                        return prevUser; // не меняем состояние
                    }
                });
                return {};
            };

            userListener = setUserListener(setUserFromPumpRoom);
            // Запускаем отправку postUserMessage постоянно в течение некоторого времени
            intervalRef.current = window.setInterval(postUserMessage, config.userPollingPeriod);

            // Через 2 секунды останавливаем отправку сообщений
            setTimeout(() => {
                if (intervalRef.current !== undefined) {
                    clearInterval(intervalRef.current);
                }
            }, config.userPollingTimeout);
        }
        setUserPollingSetup(true);

        return () => {
            if (userListener) {
                removeListener(userListener);
            }
            if (intervalRef.current !== undefined) {
                clearInterval(intervalRef.current);
            }
        };
    }, [demoUser]);

    return {user, userPollingSetup};
};

export default useUser;
