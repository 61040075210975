import React, {useState, useEffect, useRef} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
} from '@mui/material';
import {useTheme, lighten} from '@mui/material/styles';
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';
import FullscreenButton from './FullscreenButton';
import {
    PumpingTask,
    TaskFile,
    TaskSubmissionOutput,
    GameHistoryResult,
    ApplyMessageOutput,
    OutGameMessage, GameStateOutput,
} from '../types';
import {getTaskFile, updateTaskFile} from '../utils';
import ReactMarkdown from "react-markdown";

interface GameChatProps {
    taskSubmission: TaskSubmissionOutput | undefined;
    loadingResult: boolean;
    currentTask: PumpingTask;
    setCurrentTask: React.Dispatch<React.SetStateAction<PumpingTask | undefined>>;
    selectedFile: TaskFile;
    setSelectedFile: React.Dispatch<React.SetStateAction<TaskFile | undefined>>;
    applyTask: (
        currentTask: PumpingTask | undefined,
        selectedFilePath: string | undefined
    ) => Promise<boolean>;
}

const GameChat: React.FC<GameChatProps> = (
    {
        taskSubmission,
        loadingResult,
        currentTask,
        setCurrentTask,
        selectedFile,
        setSelectedFile,
        applyTask,
    }
) => {
    const theme = useTheme();
    const [historyLoaded, setHistoryLoaded] = useState(false);
    const [messages, setMessages] = useState<OutGameMessage[]>([]);
    const [gameState, setGameState] = useState<GameStateOutput | null>(null)
    const [gameDescription, setGameDescription] = useState<string | null>('Добро пожаловать в AI-игру!');
    const [chatInput, setChatInput] = useState<string>('');
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openDebug, setOpenDebug] = useState(false);

    // Ref to messagesContainer for contained scrolling
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Обработка файлов задания для извлечения истории игры
        if (historyLoaded) return;
        const gameHistoryFile = getTaskFile(currentTask, '__GAME_HISTORY.txt');
        if (!gameHistoryFile) return;
        const gameHistory: GameHistoryResult = JSON.parse(gameHistoryFile.content);
        setGameState(gameHistory.game_state);
        if (gameHistory.messages) {
            const sortedMessages = [...gameHistory.messages].sort((a, b) => a.id - b.id);
            setMessages(sortedMessages);
            setHistoryLoaded(true);
        }
        if (currentTask.description) {
            setGameDescription(currentTask.description);
        }
    }, [currentTask, setSelectedFile, selectedFile, historyLoaded]);

    useEffect(() => {
        if (taskSubmission?.stdout && gameStarted()) {
            const responseMessage: ApplyMessageOutput = JSON.parse(taskSubmission.stdout);
            setMessages(messages.concat(responseMessage.agent_message));
            setGameState(responseMessage.game_state);
        }
    }, [taskSubmission]);

    // Improved autoscroll to the last message that won't affect parent window
    useEffect(() => {
        if (messagesEndRef.current && messagesContainerRef.current) {
            // Use scrollIntoView without smooth behavior to prevent parent window scrolling
            // Or implement manual scrolling within the container
            const container = messagesContainerRef.current;
            const scrollToPos = messagesEndRef.current.offsetTop;

            // Scroll within the container only
            container.scrollTop = scrollToPos;
        }
    }, [messages, loadingResult]);

    const gameStarted = (): boolean => {
        return messages.length > 0
    }

    const getCompleteMessage = (): string => {
        if (gameState?.game_status === 'wasted') {
            return 'ПОТРАЧЕНО... Сыграем еще раз?'
        } else {
            return 'Игра завершена, спасибо!'
        }
    }

    const handleSendMessage = async () => {
        if (!chatInput) return;
        if (chatInput === '/debug') {
            setOpenDebug(true);
            setChatInput('');
            return;
        }

        const updatedCurrentTask = updateTaskFile(currentTask, '__GAME_MESSAGE.txt', chatInput);
        // Добавляем новое сообщение
        setMessages(messages.concat({
            id: 0,
            agent_id: 'human',
            name: 'human',
            photo: null,
            content: chatInput,
            created_at: new Date().toISOString(),
        }));
        setChatInput('');
        setCurrentTask(updatedCurrentTask);
        await applyTask(updatedCurrentTask, selectedFile.path);
    };

    // Функция для старта игры
    const handleStartGame = async () => {
        const firstMessage = "Привет";
        let updatedCurrentTask = updateTaskFile(currentTask, '__GAME_MESSAGE.txt', firstMessage);
        updatedCurrentTask = updateTaskFile(updatedCurrentTask, '__GAME_COMMAND.txt', '/start');
        setMessages(messages.concat({
            id: 0,
            agent_id: 'human',
            name: 'human',
            photo: null,
            content: firstMessage,
            created_at: new Date().toISOString(),
        }));
        setCurrentTask(updatedCurrentTask);
        await applyTask(updatedCurrentTask, selectedFile.path);
        updatedCurrentTask = updateTaskFile(updatedCurrentTask, '__GAME_COMMAND.txt', '');
        setCurrentTask(updatedCurrentTask);
    };

    const confirmResetGame = async () => {
        setOpenConfirm(false);
        setChatInput('');
        setMessages([]);
    };

    return (
        <Box display="flex" flexDirection="column" height="100vh" width="100%"
             fontSize={{xs: '12px', sm: '18px'}}
        >
            {/* Messages container */}
            {!gameStarted() ? (
                <Box sx={{
                    flex: 1,
                    mb: 2,
                    px: 2,
                    py: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                >
                    <Typography component="div" variant="body1" textAlign="center"
                                maxWidth={500} fontSize={{xs: '12px', sm: '18px'}}>
                        <ReactMarkdown>
                            {gameDescription}
                        </ReactMarkdown>
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleStartGame} sx={{mt: 2}}>
                        Начать игру
                    </Button>
                </Box>
            ) : (
                <Box
                    ref={messagesContainerRef}
                    sx={{
                        flex: 1,
                        overflowY: "auto",
                        px: 2,
                        py: 1,
                        mb: 2,
                        // Important: set specific height constraints to ensure scrollContainer works properly
                        height: "calc(100% - 80px)", // Adjust based on your chat input height
                    }}
                >
                    <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
                        {messages.map((msg, index) => (
                            <ChatMessage
                                key={index}
                                avatar={msg.photo}
                                name={msg.name || 'Студент'}
                                content={msg.content}
                                showName={msg.name !== 'human'}
                                color={msg.name === 'human' ? lighten(theme.palette.primary.light, 0.7) : undefined}
                                showLoader={false}
                            />
                        ))}
                        {loadingResult && (
                            <ChatMessage
                                name="..."
                                content={''}
                                showLoader={true}
                            />
                        )}
                        {gameState?.game_over && !loadingResult && <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <Typography
                                sx={{
                                    maxWidth: 500,
                                    minHeight: 100,
                                    fontWeight: "unset",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    padding: 5,
                                }}
                                variant={'h6'}
                                color={gameState.game_status === 'completed' ? theme.palette.primary.main : theme.palette.error.main}
                            >
                                {getCompleteMessage()}
                            </Typography>
                        </Box>}
                    </Box>
                    {/* Dummy element for autoscroll */}
                    <div ref={messagesEndRef}/>
                </Box>
            )}

            {/* Chat input area */}
            <Box>
                <ChatInput
                    placeholder={''}
                    value={chatInput}
                    onChange={setChatInput}
                    onSend={handleSendMessage}
                    extraControls={<>
                        {gameStarted() ?
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{paddingX: 2}}
                                onClick={() => setOpenConfirm(true)}
                            >
                                Начать сначала
                            </Button> : null
                        }
                        <FullscreenButton sx={{
                            minWidth: '75px',
                            width: '75px',
                            marginLeft: 'auto',
                            marginRight: 2,
                        }}/>
                    </>
                    }
                    canSendMessage={gameStarted() && !loadingResult && !gameState?.game_over}
                    minRows={2}
                    maxRows={6}
                />
            </Box>

            {/* Confirmation Modal */}
            <Dialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
            >
                <DialogTitle>Подтверждение сброса игры</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы уверены? Все текущие сообщения будут удалены
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirm(false)} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={confirmResetGame} color="error">
                        Сначала
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Debug Modal */}
            <Dialog
                open={openDebug}
                onClose={() => setOpenDebug(false)}
            >
                <DialogTitle>Отладочная информация</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {gameState ? <Box>
                            <Typography>
                                Статус: {gameState.game_status}
                            </Typography>
                            <Typography>
                                Этап: {gameState.stage}
                            </Typography>
                            <Typography>
                                Игра окончена: {gameState.game_over.toString()}
                            </Typography>
                        </Box> : <Typography>
                            Состояние не найдено
                        </Typography>}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDebug(false)} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default GameChat;
