import React, {useState, useEffect, useRef} from 'react';
import {
    Box,
    Button,
} from '@mui/material';
import {useTheme, lighten} from '@mui/material/styles';
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';
import FullscreenButton from './FullscreenButton';
import {
    PumpingTask,
    TaskFile,
    TaskSubmissionOutput,
    TeacherChatConfig,
    OutGameMessage,
} from '../types';
import {getTaskFile, updateTaskFile} from '../utils';

interface AgentTeacherChatProps {
    taskSubmission: TaskSubmissionOutput | undefined;
    loadingResult: boolean;
    currentTask: PumpingTask;
    setCurrentTask: React.Dispatch<React.SetStateAction<PumpingTask | undefined>>;
    selectedFile: TaskFile;
    setSelectedFile: React.Dispatch<React.SetStateAction<TaskFile | undefined>>;
    applyTask: (
        currentTask: PumpingTask | undefined,
        selectedFilePath: string | undefined
    ) => Promise<boolean>;
}

const AgentTeacherChat: React.FC<AgentTeacherChatProps> = (
    {
        taskSubmission,
        loadingResult,
        currentTask,
        setCurrentTask,
        selectedFile,
        setSelectedFile,
        applyTask,
    }
) => {
    const theme = useTheme();
    const [chatLoaded, setChatLoaded] = useState(false);
    const [messages, setMessages] = useState<OutGameMessage[]>([]);
    const [chatOver, setChatOver] = useState<boolean>(false)
    const [chatInput, setChatInput] = useState<string>('');

    // Ref to messagesContainer for contained scrolling
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const getCharacterMessage = (currentTask: PumpingTask): OutGameMessage => {
        const initialMessageFile = getTaskFile(currentTask, 'initial_message.txt');
        if (!initialMessageFile) throw new Error('initial_message.txt not found');
        const teacherChatConfigFile = getTaskFile(currentTask, 'teacher_chat.json');
        if (!teacherChatConfigFile) throw new Error('teacher_chat.json not found');
        const teacherChatConfig: TeacherChatConfig = JSON.parse(teacherChatConfigFile.content);
        return {
            id: 0,
            agent_id: 'character',
            name: teacherChatConfig.character.name,
            photo: teacherChatConfig.character.photo,
            content: initialMessageFile.content,
            created_at: new Date().toISOString(),
        }
    }

    const getHumanMessage = (content: string): OutGameMessage => {
        return {
            id: 1,
            agent_id: 'human',
            name: 'human',
            photo: null,
            content: content,
            created_at: new Date().toISOString(),
        }
    }

    const getTeacherMessage = (content: string): OutGameMessage => {
        return {
            id: 2,
            agent_id: 'teacher',
            name: 'ДуДу',
            photo: '/doudou-avatar.jpg',
            content: content,
            created_at: new Date().toISOString(),
        }
    }

    useEffect(() => {
        // Обработка файлов задания для извлечения истории игры
        if (chatLoaded) return;
        let initialMessages: OutGameMessage[] = [getCharacterMessage(currentTask)]
        const savedPromptFile = getTaskFile(currentTask, 'prompt.txt');
        if (savedPromptFile?.content) {
            initialMessages = initialMessages.concat([getHumanMessage(savedPromptFile.content)])
        }
        setMessages(initialMessages);
        if (taskSubmission?.message) {
            setChatOver(true);
        }
        setChatLoaded(true);
    }, [currentTask, setSelectedFile, selectedFile, chatLoaded, setMessages, setChatLoaded]);

    useEffect(() => {
        let teacherMessageContent = taskSubmission?.message || undefined;
        if (teacherMessageContent) {
            setMessages(prevMessages => [...prevMessages, getTeacherMessage(teacherMessageContent || '')]
            );
        }
    }, [taskSubmission, setMessages]);

    // Improved autoscroll to the last message that won't affect parent window
    useEffect(() => {
        if (messagesEndRef.current && messagesContainerRef.current) {
            // Use scrollIntoView without smooth behavior to prevent parent window scrolling
            // Or implement manual scrolling within the container
            const container = messagesContainerRef.current;
            // Scroll within the container only
            container.scrollTop = messagesEndRef.current.offsetTop;
        }
    }, [messages, loadingResult]);

    const handleSendMessage = async () => {
        if (!chatInput) return;
        const updatedCurrentTask = updateTaskFile(currentTask, 'prompt.txt', chatInput);
        // Добавляем новое сообщение
        setMessages(prevMessages => [...prevMessages, getHumanMessage(chatInput)]);
        setChatInput('');
        setCurrentTask(updatedCurrentTask);
        await applyTask(updatedCurrentTask, selectedFile.path);
        setChatOver(true);
    };

    // Функция для старта игры
    const handleStartChat = async () => {
        setMessages([
            getCharacterMessage(currentTask),
        ])
        setChatOver(false);
    };

    return (
        <Box display="flex" flexDirection="column" height="100vh" width="100%"
             fontSize={{xs: '12px', sm: '18px'}}
        >
            <Box
                ref={messagesContainerRef}
                sx={{
                    flex: 1,
                    overflowY: "auto",
                    px: 2,
                    py: 1,
                    mb: 2,
                    // Important: set specific height constraints to ensure scrollContainer works properly
                    height: "calc(100% - 80px)", // Adjust based on your chat input height
                }}
            >
                <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
                    {messages.map((msg, index) => (
                        <ChatMessage
                            key={index}
                            avatar={msg.photo}
                            name={msg.name || 'Студент'}
                            content={msg.content}
                            showName={msg.name !== 'human'}
                            color={msg.name === 'human' ? lighten(theme.palette.primary.light, 0.7) : undefined}
                            showLoader={false}
                            result={msg.agent_id === 'teacher' && taskSubmission ? (
                                taskSubmission.status === 'success' ? (
                                    'success'
                                ) : taskSubmission.status !== 'success' ? (
                                    'fail'
                                ) : null
                            ) : null}
                        />
                    ))}
                    {loadingResult && (
                        <ChatMessage
                            name="..."
                            content={''}
                            showLoader={true}
                        />
                    )}
                </Box>

                {chatOver && !loadingResult && <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 4,
                }}>
                    <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        sx={{paddingX: 2, height: 45}}
                        onClick={() => handleStartChat()}
                    >
                        Попробовать еще раз
                    </Button>
                </Box>}
                {/* Dummy element for autoscroll */}
                <div ref={messagesEndRef}/>
            </Box>

            {/* Chat input area */}
            <Box>
                <ChatInput
                    placeholder={''}
                    value={chatInput}
                    onChange={setChatInput}
                    onSend={handleSendMessage}
                    canSendMessage={!loadingResult && !chatOver}
                    minRows={2}
                    maxRows={6}
                    extraControls={<FullscreenButton sx={{
                        minWidth: '75px',
                        width: '75px',
                        marginLeft: 'auto',
                        marginRight: 2,
                    }}/>}
                />
            </Box>
        </Box>
    );
};

export default AgentTeacherChat;
