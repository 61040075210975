import React, {useState, useEffect} from 'react';
import {Box, Paper, Typography} from '@mui/material';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ResponseInput from './ResponseInput';
import ChatMessage from './ChatMessage';
import FullscreenButton from "./FullscreenButton";
import {PumpingTask, TaskFile} from '../types';
import {getTaskFile, updateTaskFile} from '../utils';

interface ResponseChatProps {
    currentTask: PumpingTask;
    setCurrentTask: React.Dispatch<
        React.SetStateAction<PumpingTask | undefined>
    >;
    selectedFile: TaskFile;
    setSelectedFile: React.Dispatch<React.SetStateAction<TaskFile | undefined>>;
    applyTask: (
        currentTask: PumpingTask | undefined,
        selectedFilePath: string | undefined
    ) => Promise<boolean>;
}

interface FeedbackCompact {
    name: string;
    photo: string | null;
    content: string | null;
}

const ResponseChat: React.FC<ResponseChatProps> = (
    {
        currentTask,
        setCurrentTask,
        selectedFile,
        setSelectedFile,
        applyTask,
    }
) => {
    const [chatInput, setChatInput] = useState<string>('');
    const [applied, setApplied] = useState(false);
    const [feedback, setFeedback] = useState<FeedbackCompact | null>(null)
    const [initialized, setInitialized] = useState(false);


    useEffect(() => {
        // Process task files
        if (currentTask.files.length > 0) {
            const inputFile = getTaskFile(currentTask, '__INPUT.txt');
            const feedbackFile = getTaskFile(currentTask, '__FEEDBACK.txt');
            setSelectedFile(inputFile);
            if (inputFile) {
                setChatInput(inputFile.content);
            }
            if (!initialized && inputFile?.content) {
                setApplied(true);
                setInitialized(true);
            }
            if (feedbackFile?.content) {
                setFeedback(JSON.parse(feedbackFile.content));
            }
        }
    }, [currentTask, setSelectedFile, selectedFile]);

    const handleSendMessage = async (): Promise<boolean> => {
        // Сохраняем chatPrompt в текущем файле только перед отправкой
        if (!chatInput)
            return false
        setInitialized(true);
        const updatedCurrentTask = updateTaskFile(currentTask, selectedFile.path, chatInput)
        setCurrentTask(updatedCurrentTask);
        let success = await applyTask(updatedCurrentTask, selectedFile.path);
        if (success) {
            setApplied(true);
        }
        return success;
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2.5}
            py={2}
            height="100vh"
            width="100%"
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={1}
                width="100%"
            >
                <Box display="flex" alignItems="center" width="100%" gap={2} height="100%">
                    <ResponseInput
                        label={'Ответ на задание'}
                        emptyInputLabel={'Ответ не может быть пустым'}
                        placeholder={''}
                        content={chatInput}
                        onChange={setChatInput}
                        onSend={handleSendMessage}
                        disabled={!!feedback}
                        minRows={5}
                        maxRows={10}
                        extraControls={<FullscreenButton sx={{minWidth: '75px', width: '75px'}}/>}
                    />
                </Box>
            </Box>
            {feedback ? <ChatMessage
                name={feedback?.name || 'Ментор'}
                avatar={feedback?.photo || "/doudou-avatar.jpg"}
                content={feedback?.content || ''}
                showLoader={false}
            /> : <Paper sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }} variant="outlined">
                {applied ? <Typography variant="body1" sx={{
                    textAlign: 'center',
                    fontSize: {xs: '12px', sm: '18px'},
                }}>
                    Эксперт уже готовит обратную связь.<br/>
                    Она появится здесь в ближайшее время.<br/>
                    <HourglassTopIcon sx={{fontSize: '2em'}}/>
                </Typography> : <Typography variant="body1" sx={{
                    textAlign: 'center',
                    fontSize: {xs: '12px', sm: '18px'},
                }}>
                    Эксперт проверит ответ и даст обратную связь.<br/>
                    Она появится в этом поле.
                </Typography>
                }
            </Paper>}
        </Box>
    );
};

export default ResponseChat;
