import React, {useEffect, useState} from 'react';
import {
    TextField,
    InputAdornment,
    Button,
    Box,
    Typography,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

interface ChatInputProps {
    value: string;
    onChange: (value: string) => void | Promise<void>;
    onSend: () => Promise<void>;
    sendButtonLabel?: string;
    emptyInputLabel?: string;
    label?: string;
    placeholder?: string;
    canSendMessage?: boolean;
    minRows?: number;
    maxRows?: number;
    extraControls?: React.ReactNode;
}

const ChatInput: React.FC<ChatInputProps> = (
    {
        value,
        onChange,
        onSend,
        sendButtonLabel = 'Отправить',
        emptyInputLabel = 'Сообщение не может быть пустым',
        label = 'Напиши свое сообщение',
        placeholder = '',
        canSendMessage = true,
        minRows = undefined,
        maxRows = undefined,
        extraControls = undefined,
    }
) => {
    const [showEmptyError, setShowEmptyError] = useState<boolean>(false);

    useEffect(() => {
        if (value) setShowEmptyError(false);
    }, [value]);

    const handleSendMessage = async () => {
        if (!value?.trim()) {
            setShowEmptyError(true);
            return;
        }
        setShowEmptyError(false);
        await onSend();
    };

    const handleKeyPress = async (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.ctrlKey && event.key === 'Enter') {
            await handleSendMessage();
        }
    };

    return (
        <TextField
            fullWidth
            multiline
            variant="outlined"
            error={showEmptyError}
            label={canSendMessage ? label : ''}
            placeholder={showEmptyError ? emptyInputLabel : placeholder}
            value={value}
            disabled={!canSendMessage}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={handleKeyPress}
            minRows={minRows}
            maxRows={maxRows}
            slotProps={{
                input: {
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            sx={{alignSelf: 'flex-end', maxHeight: 'none', width: '100%'}}
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap={1}
                                width="100%"
                            >
                                <Box sx={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
                                    {extraControls}
                                </Box>
                                {canSendMessage && <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    sx={{fontWeight: '600', display: {xs: 'none', sm: 'flex'}}}
                                >
                                    Ctrl + Enter
                                </Typography>}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    endIcon={<SendIcon sx={{marginRight: 1}}/>}
                                    onClick={handleSendMessage}
                                    disabled={!canSendMessage}
                                    sx={{flexDirection: 'row-reverse', paddingRight: 2}}
                                >
                                    {sendButtonLabel}
                                </Button>
                            </Box>
                        </InputAdornment>
                    ),
                },
            }}
        />
    );
};

export default ChatInput;
