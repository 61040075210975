import React, {useState, useEffect, useCallback} from 'react';
import {Button, SxProps, Theme} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import {
    isFullscreenAllowed,
    isFullscreen,
    toggleFullscreen,
    addFullscreenChangeListener,
    removeFullscreenChangeListener
} from '../lib/documentUtil';
import {postMessage} from '../lib/windowUtils';

interface FullscreenButtonProps {
    sx?: SxProps<Theme>;
}

const FullscreenButton: React.FC<FullscreenButtonProps> = ({sx}) => {
    const [fullscreen, setFullscreen] = useState<boolean>(isFullscreen());
    const fullscreenAllowed = isFullscreenAllowed();

    const handleToggleFullscreen = async () => {
        try {
            await toggleFullscreen();
            postMessage({
                service: 'pumproom',
                type: 'toggleFullscreen',
                state: !fullscreen,
            }, "*");
        } catch (error) {
            console.error('Ошибка при переключении полноэкранного режима:', error);
        }
    };

    const onFullscreenChange = useCallback(() => {
        setFullscreen(isFullscreen());
    }, []);

    useEffect(() => {
        if (fullscreenAllowed) {
            addFullscreenChangeListener(onFullscreenChange);
        }

        return () => {
            if (fullscreenAllowed) {
                removeFullscreenChangeListener(onFullscreenChange);
            }
        };
    }, [fullscreenAllowed, onFullscreenChange]);

    if (!fullscreenAllowed) {
        return null;
    }

    return (
        <Button
            sx={{marginLeft: '0px !important', ...sx}}
            size='small'
            variant='outlined'
            onClick={handleToggleFullscreen}
            title={fullscreen ? 'Свернуть' : 'На весь экран'}
            aria-label={fullscreen ? 'Свернуть' : 'На весь экран'}
        >
            {fullscreen ? <FullscreenExitIcon/> : <FullscreenIcon/>}
        </Button>
    );
};

export default FullscreenButton;