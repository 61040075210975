import { createTheme } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors';

export const themeChat = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    textTransform: 'none',
                    paddingTop: 0,
                    paddingBottom: 0,
                    boxShadow: 'none',
                    minHeight: '23px',
                    borderRadius: '5px',
                    fontSize: '14px',
                    fontWeight: '400',
                    gap: '5px',
                    [theme.breakpoints.up('sm')]: {
                        minHeight: '33px',
                        borderRadius: '10px',
                        fontSize: '18px',
                        fontWeight: '500',
                        gap: '10px',
                    },
                }),
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: ({ theme }) => ({
                    fontSize: "14px",
                    lineHeight: "1.65em",
                    "& fieldset": {
                        outline: "none",
                        border: "none",
                    },
                    "& textarea": {
                        [theme.breakpoints.down("sm")]: {
                            fontSize: "12px",
                        },
                        [theme.breakpoints.up("sm")]: {
                            fontSize: "18px",
                        },
                    },
                    '&.Mui-error': {
                        border: `3px solid ${theme.palette.error.light}`,
                        color: theme.palette.error.main,
                    },
                }),
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '& .MuiInputBase-root': {
                        padding: '13px 13px 13px 8px',
                        borderRadius: '15px',
                        gap: '9px',
                        flexDirection: 'column',
                        border: `3px solid ${theme.palette.secondary.main}`,
                        '&:focus, &.Mui-focused': {
                            border: `3px solid ${theme.palette.primary.light}`,
                            outline: 'none',
                        },
                        '&.Mui-error': {
                            border: `3px solid ${theme.palette.error.light}`,
                            outline: 'none',
                        },
                        [theme.breakpoints.up('sm')]: {
                            padding: '20px 41px 15px 24px', // Adjust padding for sm+
                        },
                    },
                }),
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: ({ theme }) => ({
                    fontSize: '12px',
                    fontWeight: '400',
                    color: theme.palette.text.primary,
                    [theme.breakpoints.up('sm')]: {
                        fontSize: '18px',
                    },
                    '&.Mui-error': {
                        color: theme.palette.error.light,
                    },
                    '&.MuiInputLabel-shrink': {
                        background: theme.palette.background.default,
                        fontWeight: '600',
                        padding: '0 10px',
                        top: '4px',
                        left: '14px',
                        [theme.breakpoints.up('sm')]: {
                            top: '0px',
                            left: '24px',
                        },
                    },
                }),
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: ({ theme }) => ({
                    border: `3px solid ${theme.palette.background.paper}`,
                    borderRadius: '15px',
                }),
            },
        },
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#494FE3',
            light: '#A4A7F1',
        },
        secondary: {
            main: blueGrey[50],
        },
        success: {
            main: '#40A847',
        },
        error: {
            main: '#BB5252',
            light: '#DEA9A9',
        },
        background: {
            default: '#fff',
            paper: blueGrey[50],
        },
        text: {
            primary: '#2E3235',
            secondary: '#838889',
        },
    },
});
