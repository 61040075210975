import {postMessage, addEventListener} from './windowUtils';
import {isAllowedOrigin} from './messages';
import {
    GetPumpRoomUserMessage, SetPumpRoomUserMessage, AuthResult
} from '../types';

export const setUserListener = (
    setUser: (pumpRoomUser: AuthResult) => {}
): (event: MessageEvent) => void => {
    const userListener = createUserListener(setUser) as EventListener;
    addEventListener("message", userListener);
    return userListener
}

export const postUserMessage = () => {
    const message: GetPumpRoomUserMessage = {
        service: "pumproom",
        type: "getPumpRoomUser",
    };
    postMessage(message, "*");
}

export const createUserListener = (
    setUser: (pumpRoomUser: AuthResult) => void
): ((event: MessageEvent) => void) => {
    return function handleMessage(event: MessageEvent) {
        if (!isAllowedOrigin(event)) {
            return;
        }
        const data: SetPumpRoomUserMessage | undefined = event.data;
        if (data?.service !== "pumproom" || data?.type !== "setPumpRoomUser") {
            return;
        }
        try {
            setUser(data.payload);
            window.removeEventListener("message", handleMessage);
        } catch (error) {
            console.error("Error setting User:", error);
        }
    };
};

export const getDemoUser = (): AuthResult => {
    return {
        id: 35,
        token: "xyz235851cevva77",
        email: "demo_user@inzhenerka.tech",
    };
}
