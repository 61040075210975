import React from 'react';
import {
    Backdrop,
    Box,
    Typography,
    Link,
    Button,
    IconButton,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {reload} from '../lib/windowUtils';
import config from '../config';
import {AppError} from '../types';

const ModalContainer = styled(Box)(({theme}) => ({
    fontSize: '16px',
    maxWidth: 511,
    width: '100%',
    height: 'auto',
    borderRadius: 16,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
}));

const ErrorImage = styled('img')(({theme}) => ({
    position: 'absolute',
    bottom: 0,
    left: 14,
    width: '223px',
    height: '206px',
    pointerEvents: 'none',
}));

const StyledLink = styled(Link)(({theme}) => ({
    textDecoration: 'none',
}));

interface ErrorModalProps {
    error: AppError | undefined;
    onClose: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({error, onClose}) => {
    const handleReload = () => {
        reload(); // Обновить страницу
    };
    const canClose: boolean = error?.canClose ?? false;

    return (
        <Backdrop
            open={true}
            onClick={canClose ? onClose : undefined}
            style={{zIndex: 1300}}
            sx={{
                alignItems: {xs: 'flex-end', md: 'center'},
            }}
        >
            <ModalContainer
                sx={{
                    padding: {xs: '25px 10px 20px', md: '80px 0'},
                    gap: {xs: '25px', md: '40px'},
                    justifyContent: {xs: 'flex-start'},
                    borderRadius: {xs: '26px 26px 0 0', md: '24px'},
                }}
                onClick={(e) => e.stopPropagation()}
            >
                {/* Close button */}
                {canClose && (
                    <IconButton
                        onClick={onClose}
                        sx={{
                            display: {xs: 'none', md: 'block'},
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            color: '#000',
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                )}

                <Typography
                    variant="h6"
                    align="center"
                    gutterBottom
                    sx={{
                        maxWidth: {xs: '100%', md: '100%'},
                        fontSize: '16px',
                        fontWeight: '500',
                        textAlign: 'center',
                    }}
                >
                    {error?.stage === 'load' ? (
                        <>
                            {error?.type === 'general' && 'Произошла ошибка при обработке запроса :('}
                            {error?.type === 'task_not_found' && 'Задача не найдена :('}
                            {error?.type === 'user_missing' && 'Необходима авторизация'}
                            <br/>
                            Попробуйте{' '}
                            <Link
                                component="button"
                                sx={{
                                    textDecoration: 'none',
                                    verticalAlign: 'unset',
                                    color: '#494FE3',
                                }}
                                onClick={() => window.location.reload()}
                            >
                                обновить страницу
                            </Link>
                        </>
                    ) : error?.stage === 'apply' ? (
                        <>
                            Произошла ошибка при выполнении задачи :(
                            <br/>
                            Попробуйте еще раз или{' '}
                            <Link
                                component="button"
                                sx={{
                                    textDecoration: 'none',
                                    verticalAlign: 'unset',
                                    color: '#494FE3',
                                }}
                                onClick={() => window.location.reload()}
                            >
                                обновите страницу
                            </Link>
                        </>
                    ) : (
                        <>'Что-то пошло не так :('</>
                    )}
                </Typography>

                <Typography
                    variant="body1"
                    align="center"
                    gutterBottom
                    sx={{
                        maxWidth: {xs: '100%', md: '60%'},
                        fontSize: '16px',
                        fontWeight: {xs: '400', md: '500'},
                        color: {xs: '#9AA2AB', md: '#000'},
                    }}
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Также вы можете обратиться <br/>
                    <StyledLink
                        href={config.supportUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{color: '#494FE3'}}
                    >
                        в нашу службу заботы, <br/>
                    </StyledLink>
                    мы вам поможем
                </Typography>

                <Box
                    sx={{
                        display: {xs: 'flex', md: 'none'},
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%',
                        paddingBottom: '10px',
                    }}
                >
                    <Button
                        size="large"
                        variant="contained"
                        onClick={handleReload}
                        sx={{
                            textTransform: 'none',
                            backgroundColor: '#494FE3',
                        }}
                    >
                        Обновить страницу
                    </Button>
                    <Button
                        size="large"
                        variant="contained"
                        onClick={() => window.open(config.supportUrl, '_blank')}
                        sx={{
                            textTransform: 'none',
                            backgroundColor: '#494FE3',
                        }}
                    >
                        Получить поддержку
                    </Button>
                    {canClose && (
                        <Button
                            size="large"
                            variant="outlined"
                            sx={{color: 'primary.main'}}
                            onClick={onClose}
                        >
                            Закрыть
                        </Button>
                    )}
                </Box>

                <ErrorImage
                    sx={{
                        display: {xs: 'none', md: 'block'},
                    }}
                    src="/doudou-err.png"
                    alt="Error illustration"
                />
            </ModalContainer>
        </Backdrop>
    );
};

export default ErrorModal;
