import React from 'react';
import {Typography, Paper} from '@mui/material';
import {styled} from '@mui/material/styles';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import {RichTreeView} from '@mui/x-tree-view/RichTreeView';
import {TreeViewBaseItem, TreeViewItemId} from '@mui/x-tree-view/models';
import {
    TreeItem2,
    TreeItem2Props,
    TreeItem2Label,
} from '@mui/x-tree-view/TreeItem2';
import {PumpingTask, TaskFile} from '../types';

interface FilePanelProps {
    setSelectedFile: (selectedFile: TaskFile) => void;
    selectedFile: TaskFile;
    currentTask: PumpingTask;
}

const CustomTreeItem2Label = styled(TreeItem2Label)(({theme}) => ({
    textWrap: 'nowrap',
    overflow: 'unset',
    fontSize: '0.875rem',
}));

const CustomTreeItem2 = (props: TreeItem2Props) => (
    <TreeItem2
        {...props}
        slots={{
            ...props.slots,
            label: CustomTreeItem2Label,
        }}
    />
);

interface ExtendedTreeViewBaseItem extends TreeViewBaseItem {
    isFolder?: boolean;
}

const getAllItemsWithChildrenItemIds = (items: ExtendedTreeViewBaseItem[]) => {
    const itemIds: TreeViewItemId[] = [];

    const registerItemId = (item: ExtendedTreeViewBaseItem) => {
        if (item.children?.length) {
            itemIds.push(item.id);
            item.children.forEach(registerItemId);
        }
    };

    items.forEach(registerItemId);

    return itemIds;
};

// Сортировка элементов: папки первыми, затем файлы, каждая группа по алфавиту.
const sortTreeItems = (items: ExtendedTreeViewBaseItem[]) => {
    items.sort((a, b) => {
        if (a.isFolder !== b.isFolder) {
            return a.isFolder ? -1 : 1; // сначала папки
        }
        return a.label.localeCompare(b.label); // каждый тип по алфавиту
    });

    items.forEach((item) => {
        if (item.children) {
            sortTreeItems(item.children);
        }
    });
};

const FilePanel: React.FC<FilePanelProps> = ({
                                                 setSelectedFile,
                                                 selectedFile,
                                                 currentTask,
                                             }) => {
    function createTree(files: TaskFile[]): {
        tree: ExtendedTreeViewBaseItem[];
        map: Map<string, ExtendedTreeViewBaseItem>;
    } {
        const tree: ExtendedTreeViewBaseItem[] = [];
        const map = new Map<string, ExtendedTreeViewBaseItem>();

        files.forEach((file) => {
            const parts = file.path.split('/');
            let currentLevel: ExtendedTreeViewBaseItem[] = tree;

            parts.forEach((part, index) => {
                const id = parts.slice(0, index + 1).join('/');
                let existingItem = currentLevel.find((item) => item.id === id);

                if (!existingItem) {
                    existingItem = {
                        id: id,
                        label: part,
                        children: [],
                        isFolder: index !== parts.length - 1,
                    };
                    currentLevel.push(existingItem);
                    map.set(id, existingItem);
                }

                currentLevel =
                    existingItem.children as ExtendedTreeViewBaseItem[];
            });
        });

        sortTreeItems(tree); // сортируем перед возвратом

        return {tree, map};
    }

    const {tree: treeItems, map: treeItemsMap} = React.useMemo(() => {
        return createTree(currentTask.files);
    }, [currentTask.files]);

    const onFileSelect = (
        event: React.SyntheticEvent,
        fileId: string | null
    ) => {
        if (fileId) {
            const selectedItem = treeItemsMap.get(fileId);
            if (selectedItem && !selectedItem.isFolder) {
                const newlySelectedFile: TaskFile | undefined =
                    currentTask.files.find((f) => f.path === fileId);
                if (newlySelectedFile) {
                    setSelectedFile(newlySelectedFile);
                }
            }
        }
    };

    return (
        <Paper
            sx={{
                paddingTop: {xs: '', md: '8px'},
                paddingLeft: {xs: '0px', md: '8px'},
                paddingRight: {xs: '0px', md: '8px'},
                paddingBottom: {xs: '10px', md: '22px'},
                maxHeight: '100%',
                minHeight: '100%',
                borderRadius: {xs: '0px', md: '16px'},
                border: {xs: 'none', md: ''},
                maxWidth: '100%',
                width: '100%',
            }}
            variant='outlined'
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'rgb(46, 50, 53)',
                color: '#C5C5C5',
            }}
        >
            <Typography
                sx={{
                    display: {xs: 'none', md: 'flex'},
                    alignItems: 'center',
                    fontWeight: '400',
                }}
                variant='h6'
                gutterBottom
                paddingTop={'16px'}
                marginLeft={'8px'}
                color='#fff'
            >
                <FolderOutlinedIcon
                    style={{
                        verticalAlign: 'middle',
                        marginRight: '8px',
                        color: '#A4ADB4 ',
                    }}
                />
                Файлы
            </Typography>

            <div
                style={{
                    flexGrow: 1,
                    overflow: 'auto',
                }}
            >
                <RichTreeView
                    items={treeItems}
                    slots={{item: CustomTreeItem2}}
                    defaultExpandedItems={getAllItemsWithChildrenItemIds(
                        treeItems
                    )}
                    defaultSelectedItems={treeItems[0]?.id}
                    selectedItems={selectedFile.path}
                    onSelectedItemsChange={onFileSelect}
                />
            </div>
        </Paper>
    );
};

export default FilePanel;
