import React, {useEffect, useState} from 'react';
import {
    TextField,
    InputAdornment,
    Button,
    Box,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

interface ResponseInputProps {
    content: string;
    onChange: (value: string) => void | Promise<void>;
    onSend: () => Promise<boolean>;
    sendButtonLabel?: string;
    editButtonLabel?: string;
    emptyInputLabel?: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    minRows?: number;
    maxRows?: number;
    extraControls?: React.ReactNode;
}

const ResponseInput: React.FC<ResponseInputProps> = (
    {
        content,
        onChange,
        onSend,
        sendButtonLabel = 'Отправить',
        editButtonLabel = 'Редактировать',
        emptyInputLabel = 'Сообщение не может быть пустым',
        label = 'Напиши свое сообщение',
        placeholder = '',
        disabled = false,
        minRows = undefined,
        maxRows = undefined,
        extraControls = undefined,
    }
) => {
    const [showEmptyError, setShowEmptyError] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(true);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (content) setShowEmptyError(false);
        if (!initialized && content) {
            setEditMode(false);
            setInitialized(true);
        }
    }, [content]);

    const handleContentChange = async (value: string) => {
        setInitialized(true);
        onChange(value);
    }

    const handleSendMessage = async () => {
        if (!editMode) {
            setEditMode(true);
            return
        }
        if (!content?.trim()) {
            setShowEmptyError(true);
            return;
        }
        setShowEmptyError(false);
        setLoading(true);
        let success = await onSend();
        if (success) {
            setEditMode(false);
        }
        setLoading(false);
    };

    return (
        <TextField
            fullWidth
            multiline
            variant="outlined"
            error={showEmptyError}
            label={!disabled ? label : ''}
            placeholder={showEmptyError ? emptyInputLabel : placeholder}
            value={content}
            disabled={disabled || !editMode || loading}
            onChange={(e) => handleContentChange(e.target.value)}
            minRows={minRows}
            maxRows={maxRows}
            slotProps={{
                input: {
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            sx={{alignSelf: 'flex-end', maxHeight: 'none', width: '100%'}}
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap={1}
                                width="100%"
                            >
                                <Box sx={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
                                    {extraControls}
                                </Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    onClick={handleSendMessage}
                                    disabled={disabled || loading}
                                >
                                    {loading ? <CircularProgress
                                        size='1em'
                                        sx={{marginX: 3}}
                                    /> : editMode ? sendButtonLabel : editButtonLabel}
                                </Button>
                            </Box>
                        </InputAdornment>
                    ),
                },
            }}
        />
    );
};

export default ResponseInput;
